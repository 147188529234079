<template>
    <div class="bkColor">
        <headTitle :title="title + '支出明细'" :routerPath="routerPath"></headTitle>
        <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
            @cancel="onCancel" style="margin-bottom:2vh" />
        <div class="search">
            <div>类型：</div>
            <div style="display:flex; justify-content: space-around;align-items: center;">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.type" :options="typeOption" @change="changeType" />
                </van-dropdown-menu>
                <div class="searchTitle">年份：</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.year" :options="yearOption" @change="changeYear" />
                </van-dropdown-menu>
                <div class="searchTitle">归属：</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
                </van-dropdown-menu>
            </div>
        </div>
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getDataDeatailsList">
                    <div class="listCell" v-for="(item, index) in list" :key="index" @click="goDetails(item.id, item.type)">
                        <van-swipe-cell>
                            <div class="cellName">{{ item.projectName ? item.projectName : item.userName + "的" +
                                title + (item.type == 1 ? '付款' : '报销') }}
                            </div>
                            <div class="cellText"> 流水号：{{ item.number }}</div>
                            <div class="cellText">详细说明：<div style="width:40%;display: inline-block;  vertical-align: top;">
                                    {{ item.description }}
                                </div>
                            </div>
                            <div class="cellText">交易金额：{{ item.price }}元</div>
                            <div class="cellText">创建日期：{{ item.createTime }}</div>
                        </van-swipe-cell>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="exportBtn" @click="exportFile">导出</div>
    </div>
</template>

<script>
import { statisticlist, statisticlistExport } from '../api/api'
import headTitle from '../components/headTitle.vue'
import Vue from 'vue';
import { Search, Toast, List, PullRefresh, Cell, SwipeCell, Button, Dialog } from 'vant';

import { yearOption } from '../units/units'

Vue.use(Search)
Vue.use(Toast)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Cell);
Vue.use(SwipeCell)
Vue.use(Button)
Vue.use(Dialog)
export default {
    name: 'dataDetailsList',
    components: {
        headTitle
    },
    data() {
        return {
            title: '',
            value: '',
            queryFrom: {
                year: null,
                belong: null,
                statisticsType: null,
                type: null,
                pageSize: 10,
                pageNum: 0,
                vague: ''
            },
            yearOption: yearOption,
            belongOption: [
                { text: '全部', value: null },
                { text: '大美', value: '1' },
                { text: '中创', value: '2' },
                { text: "广云仓", value: '3' },
                { text: "大丰年", value: '4' },
            ],
            typeOption: [
                { text: '全部', value: null },
                { text: "付款", value: 1 },
                { text: "报销", value: 2 },
            ],
            routerPath: '/dataAnalysis',
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
        }
    },
    mounted() {
        this.queryFrom.statisticsType = this.$route.query.type
        switch (this.queryFrom.statisticsType) {
            case '10':
                this.title = '项目'
                break
            case '20':
                this.title = '市场'
                break
            case '30':
                this.title = '行政'
                break
            case '40':
                this.title = '交通'
                break
            case '50':
                this.title = '税费'
                break
            case '60':
                this.title = '工资'
                break
            default:
                this.title = ''
        }
        this.queryFrom.year = this.$route.query.year * 1 || null
        this.queryFrom.belong = this.$route.query.belong * 1 > 0 ? this.$route.query.belong : null
        this.isMobile()
    },
    methods: {
        exportFile() {
            if (!this.equipment) {
                Toast('此功能目前只能电脑端使用！')
                return
            }
            statisticlistExport(this.queryFrom).then((res) => {
                var blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    var elink = document.createElement("a");
                    elink.download = this.title + "列表";
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, this.title + "列表");
                }
            });
        },
        getDataDeatailsList() {
            this.loading = true
            statisticlist(this.queryFrom).then(res => {
                this.list = this.queryFrom.pageNum == 1 ? res.data.rows : this.list.concat(res.data.rows)
                this.refreshing = false
                this.loading = false
                this.queryFrom.pageNum = this.queryFrom.pageNum + 1
                if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
                    this.finished = true
                }
            })
        },
        changeBelong(val) {
            this.queryFrom.belong = val
            this.onRefresh()
        },
        changeType(val) {
            this.queryFrom.type = val
            this.onRefresh()
        },
        changeYear(val) {
            this.queryFrom.year = val;
            this.onRefresh();
        },
        onSearch(val) {
            this.queryFrom.vague = val
            this.onRefresh()
        },
        //搜索框取消
        onCancel() {
            this.queryFrom.vague = ''
            this.queryFrom.state = null
            this.queryFrom.isSettle = null
            this.queryFrom.belong = null
            this.onRefresh()
        },
        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1
            this.list = []
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getDataDeatailsList();
        },
        goDetails(id, type) {
            this.$router.push({
                path: type == 1 ? '/paymentDetails' : '/reimbursementDetails',
                query: {
                    id: id, route: '/dataDetailsList?type=' + this.queryFrom.statisticsType + '&year=' + this.queryFrom.year + '&belong=' + this.queryFrom.belong
                }
            })
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    }

}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #F6F6F6;
    position: relative;
}

.search {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    background: #fff;
    font-size: 13px;
    line-height: 8vh;
}

.searchTitle {
    margin-left: 4vw;
}


/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #F6F6F6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: right;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

.exportBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 77%;
    left: 77.1%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>